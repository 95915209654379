<template>
  <div class="form-label">
    <v-data-table
      v-model="selected"
      :headers="headersData"
      :items="referenceList"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
      :show-select="true"
      class="elevation-2 mt-4"
      @update:options="updateOpt"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          color='primary'
          min-width="120"
          @click="openDetail(item)"
        >
          LIHAT DETAIL
        </v-btn>
        <v-btn
          small
          color='error'
          class="ml-2"
          min-width="120"
          @click="confirmRemove(item)"
        >
          HAPUS
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex justify-end pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="changeListPage"
      ></v-pagination>
    </div>
    <div class="d-flex justify-end mt-4">
      <v-btn 
        color="primary"
        :min-width="160"
        type="button"
        outlined
        large
        :disabled="selected.length === 0"
        class="mr-2"
        @click="selected = []"
      >
        BATALKAN PILIH
      </v-btn>
      <v-btn 
        color="primary"
        :min-width="160"
        type="button"
        large
        :disabled="selected.length === 0"
        @click="downloadData"
      >
        <v-badge
          v-if="selected.length > 0"
          color="green"
          :content="selected.length"
        >
           UNDUH
        </v-badge>
        <div v-else>UNDUH</div>
      </v-btn>
    </div>
    <dialog-info ref="dialogInfoSuccessDonwload" :info="infoSuccess"/>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccessRemove"/>
    <dialog-confirm ref="dialogConfirmRemoveLabel" :info="info" :action="removeLabel"/>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import moment from 'moment'
import { text } from '@/apps/web/constants/text'
import loadscreen from '@/utils/common/loadScreen'
import DialogInfo from '@/components/common/DialogInfo'
import DialogConfirm from '@/components/common/DialogConfirm'
import {
  LABEL_DETAIL
} from '@/constants/pages'
import {
  API_DASH_LABEL_LIST,
  API_DASH_LABEL_DELETE,
  API_DASH_LABEL_EXPORT
} from '@/constants/apis'
import request from '@/utils/request'
  export default {
    name: 'FormLabel',
    props: {
      filterData: {
        type: Object,
        default: () => {}
      },
    },
    components: {
      DialogInfo,
      DialogConfirm,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        isShowAlertLabel: false,
        alertMsgLabel: '',
        headers: [
          {
            text: 'Kategori',
            align: 'start',
            value: 'category_name',
            class: 'black--text subtitle-2'
          },
          { text: 'Nama Lengkap', value: 'name', class: 'black--text subtitle-2' },
          { text: 'Keterangan', value: 'description', sortable: false, class: 'black--text subtitle-2' },
          { text: 'Email', value: 'email', class: 'black--text subtitle-2' },
          { text: 'Kota', value: 'city', class: 'black--text subtitle-2' },
          { text: 'Provinsi', value: 'province', class: 'black--text subtitle-2' },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        referenceList: [],
        totalList: 0,
        loading: false,
        limit: 10,
        options: {},
        page: 1,
        pageCount: 1,
        selected: [],
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.generate',
          btn: 'dialog.success.btn'
        },
        infoSuccessRemove: {
          title: 'dialog.success',
          desc: 'dialog.success.remove',
          btn: 'dialog.success.btn'
        },
        info: {
          desc: 'Anda yakin untuk menghapus label?'
        },
        selectedRemoveId: ''
      }
    },
    computed: {
      headersData () {
        return this.headers
      }
    },
    methods: {
      confirmRemove (item) {
        this.$refs.dialogConfirmRemoveLabel && this.$refs.dialogConfirmRemoveLabel.show()
        this.selectedRemoveId = item.id
      },
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.setFilterData()
        }
      },
      setFilterData () {
        this.page = 1
        this.getDataFromApi()
      },
      getDataFromApi () {
        this.loading = true
        this.isShowAlertLabel = false
        const params = {}
        params.page = this.page
        params.limit = this.limit
        if (this.filterData.name) params.fullname = this.filterData.name
        if (this.filterData.category) params.category_id = this.filterData.category
        if (this.filterData.city) params.city = this.filterData.city
        if (this.filterData.province) params.province = this.filterData.province
        
        loadscreen.hide()
        request(API_DASH_LABEL_LIST, params).then(res => {
          if (res.success) {

            this.referenceList = res.list
            this.referenceList && this.referenceList.map(itm => {
              itm.name = `${itm.prefix} ${itm.fullname}, ${itm.suffix}`
            })
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsgLabel = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlertLabel = true
            this.$emit('handleAlertLabel', this.isShowAlertLabel, this.alertMsgLabel)
          }
          loadscreen.hide()
        })
      },
      changeListPage () {
        this.getDataFromApi()
      },
      openDetail (item) {
        this.$store.dispatch('dashboard/setSelectedLabel', item)
        this.$router.push({
          name: LABEL_DETAIL,
          query: {
            source: 'edit',
            id: item.id
          }
        }).catch(() => {})
      },
      removeLabel () {
        request(API_DASH_LABEL_DELETE, {
          id: this.selectedRemoveId || ''
        }).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
            this.setFilterData()
          } else {
            this.alertMsgLabel = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlertLabel = true
            this.$emit('handleAlertLabel', this.isShowAlertLabel, this.alertMsgLabel)
          }
          loadscreen.hide()
        })
      },
      downloadData () {
        const id = []
        this.selected.map(itm => {
          id.push(itm.id)
        })
        request(API_DASH_LABEL_EXPORT, {
          id
        }).then(res => {
          if (res.success) {
            window.open(res.link_file, '_blank')
            this.$refs.dialogInfoSuccessDonwload && this.$refs.dialogInfoSuccessDonwload.show()
            this.selected = []
          } else {
            this.alertMsgLabel = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlertLabel = true
            this.$emit('handleAlertLabel', this.isShowAlertLabel, this.alertMsgLabel)
          }
          loadscreen.hide()
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.form-label {

}
</style>