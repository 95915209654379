<template>
  <div class="label">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.report.category.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlertCategory"
          >{{ alertMsgCategory }}</v-alert>
          <v-btn 
            color="primary"
            :min-width="160"
            class="mb-2"
            @click="openModalCategory()"
            large
          >
            TAMBAH
          </v-btn>
          <form-category
            ref="categoryData" 
            @handleAlertCategory="handleAlertCategory()" 
          />
          <modal-category ref="modalCategory" @reload="reloadCategory()"/>
        </v-card>
        <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.report.lable.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlertLabel"
          >{{ alertMsgLabel }}</v-alert>
          <filter-label @setFilterData="setFilterLabel"  @handleAlertLabel="handleAlertLabel()" />
          <v-divider class="my-8"></v-divider>
          <v-btn 
            color="primary"
            :min-width="160"
            class="mb-2"
            @click="goToDetail('add')"
            large
          >
            TAMBAH
          </v-btn>
          <form-label
            ref="labelData" 
            :filterData="filterLabel" 
            @handleAlertLabel="handleAlertLabel()" 
          />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import FilterLabel from '@/apps/dashboard/components/filters/filterLabel'
import FormLabel from '../components/tables/formLabel'
import FormCategory from '../components/tables/formCategory'
import ModalCategory from '../components/modalCategory'
import {
  LABEL_DETAIL
} from '@/constants/pages'
  export default {
    name: 'Label',
    components: {
      FilterLabel,
      FormLabel,
      FormCategory,
      ModalCategory
    },
    data () {
      return {
        page: 1,
        isShowAlertTable: false,
        alertMsgTable: '',
        isShowAlertLabel: false,
        alertMsgLabel: '',
        isShowAlertCategory: false,
        alertMsgCategory: '',
        filterData: {
          name: '',
          status: 'accepted',
          program_study: '',
          season: '',
          period: ''
        },
        filterLabel: {
          name: '',
          category: 'Semua',
          city: '',
          province: '',
        },
        isShowTables: false
      }
    },
    computed: {
      
    },
    methods: {
      setFilterLabel (params) {
        this.filterLabel.name = params.name
        this.filterLabel.category = params.category === 'Semua' ? '' : params.category
        this.filterLabel.city = params.city
        this.filterLabel.province = params.province
        this.handleAlertLabel(false, '')
        this.$refs.labelData && this.$refs.labelData.setFilterData()
      },
      handleAlertLabel (isShowAlertLabel, msg) {
        this.isShowAlertLabel = isShowAlertLabel
        this.alertMsgLabel = msg
      },
      handleAlertCategory (isShowAlert, msg) {
        this.isShowAlertCategory = isShowAlert
        this.alertMsgCategory = msg
      },
      goToDetail (source) {
        this.$router.push({
          name: LABEL_DETAIL,
          query: {
            source
          }
        }).catch(() => {})
      },
      openModalCategory () {
        this.$refs.modalCategory && this.$refs.modalCategory.showModal()
      },
      reloadCategory () {
        this.$refs.categoryData && this.$refs.categoryData.setFilterData()
      }
    }
  }
</script>

<style lang="scss" scoped>
.label {

}
</style>