<template>
  <div class="form-category">
    <v-data-table
      :headers="headersData"
      :items="categoryList"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{'items-per-page-options':[10, 25, 50, 100]}"
      class="elevation-2 mt-4"
      @update:options="updateOpt"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          color='primary'
          class="mr-2"
          min-width="120"
          @click="editCategory(item)"
        >
          PERBARUI
        </v-btn>
        <v-btn
          small
          color='error'
          min-width="120"
          @click="removeCategory(item)"
        >
          HAPUS
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex justify-end pt-2 mb-9">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="changeListPage"
      ></v-pagination>
    </div>
    <modal-category ref="modalCategory" @reload="setFilterData()"/>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess"/>
    <dialog-confirm ref="dialogConfirmRemoveCategory" :info="info" :action="deleteCategory"/>
  </div>
</template>

<script>
import {get} from 'dot-prop'
import moment from 'moment'
import { text } from '@/apps/web/constants/text'
import loadscreen from '@/utils/common/loadScreen'
import ModalCategory from '../modalCategory'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
import {
  API_DASH_LABEL_CATEGORY,
  API_DASH_REMOVE_LABEL_CATEGORY
} from '@/constants/apis'
import request from '@/utils/request'
  export default {
    name: 'FormCategory',
    props: {
      filterData: {
        type: Object,
        default: () => {}
      },
    },
    components: {
      ModalCategory,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        headers: [
          {
            text: 'Kategori',
            align: 'start',
            value: 'name',
            class: 'black--text subtitle-2',
            width: '70%',
          },
          { text: 'Detail', value: 'actions', sortable: false, class: 'black--text subtitle-2' }
        ],
        categoryList: [],
        totalList: 0,
        loading: false,
        limit: 10,
        options: {},
        page: 1,
        pageCount: 1,
        info: {
          desc: 'Anda yakin untuk menghapus kategori?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.remove',
          btn: 'dialog.success.btn'
        },
        selectedItem: {}
      }
    },
    computed: {
      headersData () {
        return this.headers
      }
    },
    mounted () {
      this.setFilterData()
    },
    methods: {
      updateOpt (e) {
        if (e.itemsPerPage !== this.limit) {
          this.limit = e.itemsPerPage
          this.setFilterData()
        }
      },
      setFilterData () {
        this.page = 1
        this.getDataFromApi()
      },
      getDataFromApi () {
        this.loading = true
        this.isShowAlert = false
        const params = {}
        params.page = this.page
        params.limit = this.limit
        
        request(API_DASH_LABEL_CATEGORY, params).then(res => {
          if (res.success) {
            this.categoryList = res.list
            this.pageCount = res.pagination.total_page
            this.loading = false
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
            this.loading = false
            this.$emit('handleAlertCategory', this.isShowAlert, this.alertMsg)
          }
          loadscreen.hide()
        })
      },
      changeListPage () {
        this.getDataFromApi()
      },
      editCategory (item) {
        this.$refs.modalCategory && this.$refs.modalCategory.showModal(item)
      },
      removeCategory (item) {
        this.selectedItem = item
        this.$refs.dialogConfirmRemoveCategory && this.$refs.dialogConfirmRemoveCategory.show()
      },
      deleteCategory () {
        const params = {
          id: this.selectedItem.id || ''
        }
        request(API_DASH_REMOVE_LABEL_CATEGORY, params).then(res => {
          if (res.success) {
            this.setFilterData()
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
            this.$emit('handleAlertCategory', this.isShowAlert, this.alertMsg)
          }
          loadscreen.hide()
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
.form-category {

}
</style>