<template>
  <div class="filter-label">
    <validation-observer v-slot="{ invalid }">
      <v-form id="filter-label" @submit.prevent="checkLabel">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nama"
              rules=""
            >
              <p class="title-14 mb-2">Nama</p>
              <v-text-field
                placeholder="Nama"
                v-model="name"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <div >
              <p class="title-14 mb-2">Kategori</p>
              <v-autocomplete 
                v-model="category"
                :items="categoriesData" 
                dense
                outlined
                item-text="name" 
                item-value="id" 
                return-object
                autocomplete="off"
              >
                <template v-slot:append-item>
                  <div v-intersect="onIntersect" class="pa-4 primary--text">
                    Loading more items ...
                  </div>
                </template>
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
           <validation-provider
              v-slot="{ errors }"
              name="Kota"
              rules=""
            >
              <p class="title-14 mb-2">Kota</p>
              <v-text-field
                placeholder="Kota"
                v-model="city"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Provinsi"
              rules=""
            >
              <p class="title-14 mb-2">Provinsi</p>
              <v-text-field
                placeholder="Provinsi"
                v-model="province"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="filter-label"
            large
            :disabled="invalid"
          >
            TEMUKAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
  // Libs
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { get } from 'dot-prop'
  import moment from 'moment'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'
  
  // Constants
  import {
    API_DASH_LABEL_CATEGORY
  } from '@/constants/apis'

  export default {
    name: 'FilterStudent',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data () {
      return {
        name: '',
        city: '',
        province: '',
        category: 'Semua',
        isExpand: false,
        page: 1,
        limit: 10,
        pageCount: 0,
        categoriesData: [{
          name: 'Semua',
          id: 'Semua'
        }],
      }
    },
    computed: {
    },
    created () {
      this.getCategories()
    },
    methods: {
      getCategories () {
        if (this.page <= this.pageCount || this.pageCount === 0) {
          const params = {}
          params.page = this.page
          params.limit = this.limit
          
          request(API_DASH_LABEL_CATEGORY, params).then(res => {
            if (res.success) {
              this.categoriesData = [...this.categoriesData, ...res.list]
              this.pageCount = res.pagination.total_page
            }
            loadscreen.hide()
          })
        }
      },
      onIntersect () {
        this.page += 1
        this.getCategories()
      },
      async checkLabel () {
        loadscreen.show()
        const params = {
          name: this.name,
          category: this.category.id,
          province: this.province,
          city: this.city
        }
        this.$emit('setFilterData', params)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.filter-label {
  .status-info {
    color: black;
    font-size: 14px;
    margin: 0;
  }
}
</style>
