<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Kategori Label</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-alert
              dismissible
              type="error"
              v-model="isShowAlert"
            >{{ alertMsg }}</v-alert>
            <validation-observer >
              <v-form id="category-label" @submit.prevent="checkLabelCategory">
                <v-row no-gutters class="general-form">
                  <v-col
                    cols="12"
                    class="general-form__col"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="kategory"
                      rules="required"
                    >
                      <p class="title-14 mb-2">Kategori</p>
                      <v-text-field
                        placeholder="kategory"
                        v-model="category"
                        :error-messages="errors"
                        clearable
                        type="text"
                        outlined
                        dense
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-form>
            </validation-observer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            min-width="120"
            text
            @click="dialog = false"
          >
            KEMBALI
          </v-btn>
          <v-btn
            color="primary"
            min-width="120"
            type="submit"
            form="category-label"
            :disabled="!category"
            large
            @click="dialog = false"
          >
            SIMPAN
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="hideModal"/>
  </v-row>
</template>

<script>
import {get} from 'dot-prop'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import {
  API_DASH_CREATE_LABEL_CATEGORY
} from '@/constants/apis'
import request from '@/utils/request'
import loadscreen from '@/utils/common/loadScreen'
  import DialogInfo from '@/components/common/DialogInfo'
  export default {
    data () {
      return {
        dialog: false,
        category: '',
        isShowAlert: false,
        alertMsg: '',
        id: '',
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogInfo
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      showModal (item = {}) {
        this.dialog = true
        if (item.id) {
          this.id = item.id
          this.category = item.name || ''
        }
      },
      hideModal () {
        this.dialog = false
      },
      checkLabelCategory () {
        const params = {}
        params.name = this.category
        if (this.id) {
          params.id = this.id
        }
        loadscreen.show()
        
        request(API_DASH_CREATE_LABEL_CATEGORY, params).then(res => {
          if (res.success) {
            this.$emit('reload')
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>