var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-label"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"filter-label"},on:{"submit":function($event){$event.preventDefault();return _vm.checkLabel.apply(null, arguments)}}},[_c('v-row',{staticClass:"general-form",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Nama","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Nama")]),_c('v-text-field',{attrs:{"placeholder":"Nama","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('div',[_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Kategori")]),_c('v-autocomplete',{attrs:{"items":_vm.categoriesData,"dense":"","outlined":"","item-text":"name","item-value":"id","return-object":"","autocomplete":"off"},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticClass:"pa-4 primary--text"},[_vm._v(" Loading more items ... ")])]},proxy:true}],null,true),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Kota","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Kota")]),_c('v-text-field',{attrs:{"placeholder":"Kota","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Provinsi","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Provinsi")]),_c('v-text-field',{attrs:{"placeholder":"Provinsi","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","min-width":160,"type":"submit","form":"filter-label","large":"","disabled":invalid}},[_vm._v(" TEMUKAN ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }